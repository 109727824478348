.cl_colroom{
   width: 120px;
   text-align: left;
}

.cl_colhist{
    width: 80px;
    text-align: center;
 }

 .patientcol1{
    width:50px;
 }

 .patientcol2{
    width:100px;
 }

 .patientcol3{
    width:100px;
 }

 .patientcolHist{
    width:80px;
    text-align: center;
    vertical-align:middle;
 }

 .patientcolOp{
    width:80px;
    text-align: center;
    vertical-align:middle;
 }