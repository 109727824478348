
.cl_col5{
    text-align: center;
    vertical-align:middle;
    width:80px;
}

.cl_col1{
    width: 100px;
}

.cl_col2{
    width: 100px;
}

.cl_col3{
    width: 100px;
}